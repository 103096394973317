var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lg:w-3/5 mx-auto" },
    [
      _c("div", { staticClass: "main-icon" }, [
        _vm.queryResult.dataStatus === _vm.dataStatus.available &&
        _vm.eligibleAmount > 0
          ? _c(
              "svg",
              {
                attrs: {
                  width: "69",
                  height: "68",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("rect", {
                  attrs: {
                    x: ".25",
                    width: "68",
                    height: "68",
                    rx: "34",
                    fill: "#F5FFFB",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "m17.9609 34.8693.5303.5303-.5303-.5303c-1.0729 1.0729-1.0729 2.8078 0 3.8807l8.36 8.36c1.0729 1.0729 2.8078 1.0729 3.8807 0l21.16-21.16c1.0729-1.0729 1.0729-2.8078 0-3.8807-1.0729-1.0729-2.8078-1.0729-3.8807 0l-19.2294 19.21-6.4099-6.41c-1.0729-1.0729-2.8078-1.0729-3.8807 0Z",
                    fill: "#2C994B",
                    stroke: "#2C994B",
                    "stroke-width": "1.5",
                  },
                }),
              ]
            )
          : _vm.queryResult.dataStatus === _vm.dataStatus.pending ||
            _vm.queryResult.dataStatus === _vm.dataStatus.pendingAdminReview
          ? _c(
              "svg",
              {
                attrs: {
                  width: "68",
                  height: "68",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("rect", {
                  attrs: {
                    width: "68",
                    height: "68",
                    rx: "34",
                    fill: "#F5FBFF",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M38.2311 52.02v-.25h-8.46v.25c0 2.3381 1.8919 4.23 4.23 4.23 2.3381 0 4.23-1.8919 4.23-4.23Zm12.5268-5.6168-2.5068-2.5068V32c0-6.7499-4.706-12.4024-11-13.8602V16c0-1.7981-1.4519-3.25-3.25-3.25s-3.25 1.4519-3.25 3.25v2.1398c-6.294 1.4578-11 7.1103-11 13.8602v11.8964l-2.5068 2.5068c-1.4145 1.4146-.4301 3.8468 1.5768 3.8468h30.34c2.0242 0 3.0132-2.4304 1.5968-3.8468ZM32.2511 41.75v-3.5h3.5v3.5h-3.5Zm3.5-9.75c0 .9619-.7881 1.75-1.75 1.75s-1.75-.7881-1.75-1.75v-4c0-.9619.7881-1.75 1.75-1.75s1.75.7881 1.75 1.75v4Z",
                    fill: "#0EA2F5",
                    stroke: "#0EA2F5",
                    "stroke-width": ".5",
                  },
                }),
              ]
            )
          : _c(
              "svg",
              {
                attrs: {
                  width: "68",
                  height: "68",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("rect", {
                  attrs: {
                    width: "68",
                    height: "68",
                    rx: "34",
                    fill: "#FFFAF8",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M36 44.5h.5v-5h-5v5H36Zm.5-18.5c0-1.3761-1.1239-2.5-2.5-2.5s-2.5 1.1239-2.5 2.5v8c0 1.3761 1.1239 2.5 2.5 2.5s2.5-1.1239 2.5-2.5v-8Zm-2.52-12.5C22.6632 13.5 13.5 22.6845 13.5 34s9.1632 20.5 20.48 20.5c11.3358 0 20.52-9.1835 20.52-20.5s-9.1842-20.5-20.52-20.5Zm.02 36c-8.5639 0-15.5-6.9361-15.5-15.5S25.4361 18.5 34 18.5 49.5 25.4361 49.5 34 42.5639 49.5 34 49.5Z",
                    fill: "#DE5F35",
                    stroke: "#DE5F35",
                  },
                }),
              ]
            ),
      ]),
      _c("div", { staticClass: "text mt-8" }, [
        _c("h4", { staticClass: "font-bold text-dark text-lg" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
      _vm.queryResult.dataStatus === _vm.dataStatus.failed ||
      _vm.queryResult.dataStatus === _vm.dataStatus.available
        ? _c("div", { staticClass: "mt-4 text-grey" }, [
            _vm.queryResult.dataStatus === _vm.dataStatus.failed
              ? _c("div", [
                  _c("p", { staticClass: "text-grey mt-4" }, [
                    _vm._v(
                      "We were unable to pull data from the connected account."
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.queryResult.dataStatus === _vm.dataStatus.available
              ? _c("div", [
                  _c("p", { staticClass: "text-grey font-semibold" }, [
                    _vm._v(
                      "Qualified credit: " +
                        _vm._s(
                          _vm._f("formatMoney")(_vm.queryResult.eligibleAmount)
                        )
                    ),
                  ]),
                  _c("p", { staticClass: "font-semibold" }, [
                    _vm._v(
                      "Number of transactions pulled: " +
                        _vm._s(_vm.queryResult.transactionCount || 0)
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "If you have performed more transactions than the above in the past three(3) months, check below"
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mt-3 rounded-lg border border-lightPurple p-4" },
              [
                _c(
                  "div",
                  { staticClass: "flex items-center justify-between" },
                  [
                    _c("p", { staticClass: "text-sm text-grey" }, [
                      _vm._v("Here are things you can do"),
                    ]),
                    _c("svg-icon", {
                      staticClass: "cursor-pointer",
                      attrs: {
                        color: "#1E1D20",
                        type: "mdi",
                        path: _vm.svgPath.arrowDown,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.showExplainer = !_vm.showExplainer
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("AnimateHeight", [
                  _vm.showExplainer
                    ? _c("div", { staticClass: "explainer-container" }, [
                        _vm.queryResult.dataStatus === _vm.dataStatus.available
                          ? _c("div", { staticClass: "explainer-grid mt-2" }, [
                              _c(
                                "div",
                                { staticClass: "icon-container" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      color: "#6956C8",
                                      type: "mdi",
                                      path: _vm.svgPath.link,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-dark text-xs md:text-sm",
                                  },
                                  [
                                    _vm._v(
                                      "Reconnect your account or try another account to get access to a higher credit limit."
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.queryResult.dataStatus === _vm.dataStatus.failed
                          ? _c("div", { staticClass: "explainer-grid mt-4" }, [
                              _c(
                                "div",
                                { staticClass: "icon-container" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      color: "#6956C8",
                                      type: "mdi",
                                      path: _vm.svgPath.refresh,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-dark text-xs md:text-sm",
                                  },
                                  [
                                    _vm._v(
                                      "Please retry the same account after a few minutes or connect a different account."
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "explainer-grid mt-4" }, [
                          _c(
                            "div",
                            { staticClass: "icon-container" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  color: "#6956C8",
                                  type: "mdi",
                                  path: _vm.svgPath.attachment,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "p",
                              { staticClass: "text-dark text-xs md:text-sm" },
                              [
                                _vm._v(
                                  " Use the other method “" +
                                    _vm._s(_vm.alternateVerification) +
                                    "” "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-brandPurple cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("back")
                                      },
                                    },
                                  },
                                  [_vm._v(" here")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "explainer-grid mt-4" }, [
                          _c(
                            "div",
                            { staticClass: "icon-container" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  color: "#6956C8",
                                  type: "mdi",
                                  path: _vm.svgPath.alert,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "p",
                              { staticClass: "text-dark text-xs md:text-sm" },
                              [_vm._v("Verifying your personal information")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.queryResult.dataStatus === _vm.dataStatus.pending ||
      _vm.queryResult.dataStatus === _vm.dataStatus.pendingAdminReview
        ? _c("p", { staticClass: "text-grey mt-" }, [
            _vm._v(
              " We are still assessing your financial health. Please check back after some time. "
            ),
          ])
        : _vm._e(),
      _vm.queryResult.dataStatus === _vm.dataStatus.flagged
        ? _c("p", { staticClass: "text-grey mt-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.queryResult.flaggedReason
                    ? _vm.queryResult.flaggedReason
                    : "There was an error getting information from your account please try again or connect another account "
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.queryResult.dataStatus === _vm.dataStatus.pending ||
      _vm.queryResult.dataStatus === _vm.dataStatus.pendingAdminReview
        ? _c("Button", {
            staticClass: "mt-8",
            attrs: { text: "Close", width: "w-full" },
            on: { click: _vm.handleClose },
          })
        : _vm.queryResult.dataStatus !== _vm.dataStatus.available ||
          _vm.queryResult.eligibleAmount < 1 ||
          _vm.queryResult.dataStatus === _vm.dataStatus.flagged
        ? _c(
            "div",
            [
              _c("Button", {
                staticClass: "mt-8",
                attrs: { text: "Try again", width: "w-full" },
                on: { click: _vm.handleConnectBank },
              }),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-semibold text-center mt-4 cursor-pointer",
                  on: { click: _vm.handleClose },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("Button", {
                staticClass: "mt-8",
                attrs: { text: "I'm okay with my result", width: "w-full" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("success")
                  },
                },
              }),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-semibold text-center mt-4 cursor-pointer",
                  on: { click: _vm.handleConnectBank },
                },
                [_vm._v("Try again")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }