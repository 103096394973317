<template>
  <div class="lg:w-3/5 mx-auto">
    <!-- icons -->
    <div class="main-icon">
      <svg
        v-if="queryResult.dataStatus === dataStatus.available && eligibleAmount > 0"
        width="69"
        height="68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x=".25" width="68" height="68" rx="34" fill="#F5FFFB" />
        <path
          d="m17.9609 34.8693.5303.5303-.5303-.5303c-1.0729 1.0729-1.0729 2.8078 0 3.8807l8.36 8.36c1.0729 1.0729 2.8078 1.0729 3.8807 0l21.16-21.16c1.0729-1.0729 1.0729-2.8078 0-3.8807-1.0729-1.0729-2.8078-1.0729-3.8807 0l-19.2294 19.21-6.4099-6.41c-1.0729-1.0729-2.8078-1.0729-3.8807 0Z"
          fill="#2C994B"
          stroke="#2C994B"
          stroke-width="1.5"
        />
      </svg>
      <svg
        v-else-if="queryResult.dataStatus === dataStatus.pending || queryResult.dataStatus === dataStatus.pendingAdminReview"
        width="68"
        height="68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="68" height="68" rx="34" fill="#F5FBFF" />
        <path
          d="M38.2311 52.02v-.25h-8.46v.25c0 2.3381 1.8919 4.23 4.23 4.23 2.3381 0 4.23-1.8919 4.23-4.23Zm12.5268-5.6168-2.5068-2.5068V32c0-6.7499-4.706-12.4024-11-13.8602V16c0-1.7981-1.4519-3.25-3.25-3.25s-3.25 1.4519-3.25 3.25v2.1398c-6.294 1.4578-11 7.1103-11 13.8602v11.8964l-2.5068 2.5068c-1.4145 1.4146-.4301 3.8468 1.5768 3.8468h30.34c2.0242 0 3.0132-2.4304 1.5968-3.8468ZM32.2511 41.75v-3.5h3.5v3.5h-3.5Zm3.5-9.75c0 .9619-.7881 1.75-1.75 1.75s-1.75-.7881-1.75-1.75v-4c0-.9619.7881-1.75 1.75-1.75s1.75.7881 1.75 1.75v4Z"
          fill="#0EA2F5"
          stroke="#0EA2F5"
          stroke-width=".5"
        />
      </svg>
      <svg v-else width="68" height="68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="68" height="68" rx="34" fill="#FFFAF8" />
        <path
          d="M36 44.5h.5v-5h-5v5H36Zm.5-18.5c0-1.3761-1.1239-2.5-2.5-2.5s-2.5 1.1239-2.5 2.5v8c0 1.3761 1.1239 2.5 2.5 2.5s2.5-1.1239 2.5-2.5v-8Zm-2.52-12.5C22.6632 13.5 13.5 22.6845 13.5 34s9.1632 20.5 20.48 20.5c11.3358 0 20.52-9.1835 20.52-20.5s-9.1842-20.5-20.52-20.5Zm.02 36c-8.5639 0-15.5-6.9361-15.5-15.5S25.4361 18.5 34 18.5 49.5 25.4361 49.5 34 42.5639 49.5 34 49.5Z"
          fill="#DE5F35"
          stroke="#DE5F35"
        />
      </svg>
    </div>
    <!-- title text -->
    <div class="text mt-8">
      <h4 class="font-bold text-dark text-lg">{{ title }}</h4>
    </div>
    <!-- if you have a status of available or failed -->
    <div class="mt-4 text-grey" v-if="queryResult.dataStatus === dataStatus.failed || queryResult.dataStatus === dataStatus.available">
      <!-- if the pooling failed -->
      <div v-if="queryResult.dataStatus === dataStatus.failed">
        <p class="text-grey mt-4">We were unable to pull data from the connected account.</p>
      </div>
      <!-- if we get an available status -->
      <div v-if="queryResult.dataStatus === dataStatus.available">
        <p class="text-grey font-semibold">Qualified credit: {{ queryResult.eligibleAmount | formatMoney }}</p>
        <p class="font-semibold">Number of transactions pulled: {{ queryResult.transactionCount || 0 }}</p>
        <p>If you have performed more transactions than the above in the past three(3) months, check below</p>
      </div>

      <div class="mt-3 rounded-lg border border-lightPurple p-4">
        <div class="flex items-center justify-between">
          <p class="text-sm text-grey">Here are things you can do</p>
          <svg-icon
            @click.stop.native="showExplainer = !showExplainer"
            class="cursor-pointer"
            color="#1E1D20"
            type="mdi"
            :path="svgPath.arrowDown"
          ></svg-icon>
        </div>
        <!-- explainer -->
        <AnimateHeight>
          <div class="explainer-container" v-if="showExplainer">
            <div class="explainer-grid mt-2" v-if="queryResult.dataStatus === dataStatus.available">
              <div class="icon-container">
                <svg-icon color="#6956C8" type="mdi" :path="svgPath.link"></svg-icon>
              </div>
              <div>
                <p class="text-dark text-xs md:text-sm">Reconnect your account or try another account to get access to a higher credit limit.</p>
              </div>
            </div>
            <div class="explainer-grid mt-4" v-if="queryResult.dataStatus === dataStatus.failed">
              <div class="icon-container">
                <svg-icon color="#6956C8" type="mdi" :path="svgPath.refresh"></svg-icon>
              </div>
              <div>
                <p class="text-dark text-xs md:text-sm">Please retry the same account after a few minutes or connect a different account.</p>
              </div>
            </div>
            <div class="explainer-grid mt-4">
              <div class="icon-container">
                <svg-icon color="#6956C8" type="mdi" :path="svgPath.attachment"></svg-icon>
              </div>
              <div>
                <p class="text-dark text-xs md:text-sm">
                  Use the other method “{{ alternateVerification }}”
                  <span class="text-brandPurple cursor-pointer" @click="$emit('back')"> here</span>
                </p>
              </div>
            </div>
            <div class="explainer-grid mt-4">
              <div class="icon-container">
                <svg-icon color="#6956C8" type="mdi" :path="svgPath.alert"></svg-icon>
              </div>
              <div>
                <p class="text-dark text-xs md:text-sm">Verifying your personal information</p>
              </div>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
    <!-- text if it is pending or admin pending -->
    <p class="text-grey mt-" v-if="queryResult.dataStatus === dataStatus.pending || queryResult.dataStatus === dataStatus.pendingAdminReview">
      We are still assessing your financial health. Please check back after some time.
    </p>
    <!-- if data status is flagged; normally we should expect reason along side with it -->
    <p class="text-grey mt-4" v-if="queryResult.dataStatus === dataStatus.flagged">
      {{
        queryResult.flaggedReason
          ? queryResult.flaggedReason
          : `There was an error getting information from your
      account please try again or connect another account `
      }}
    </p>

    <!-- button section -->
    <Button
      class="mt-8"
      v-if="queryResult.dataStatus === dataStatus.pending || queryResult.dataStatus === dataStatus.pendingAdminReview"
      text="Close"
      @click="handleClose"
      width="w-full"
    />
    <div
      v-else-if="queryResult.dataStatus !== dataStatus.available || queryResult.eligibleAmount < 1 || queryResult.dataStatus === dataStatus.flagged"
    >
      <Button class="mt-8" text="Try again" width="w-full" @click="handleConnectBank" />
      <p class="text-sm font-semibold text-center mt-4 cursor-pointer" @click="handleClose">Close</p>
    </div>

    <div v-else>
      <Button class="mt-8" text="I'm okay with my result" width="w-full" @click="$emit('success')" />
      <p class="text-sm font-semibold text-center mt-4 cursor-pointer" @click="handleConnectBank">Try again</p>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import { DATA_STATUS } from "@/utils/sharedData/home";
  import { mdiLink, mdiAttachment, mdiAlertCircleOutline, mdiChevronDown, mdiRefresh } from "@mdi/js";
  import { Button } from "@/UI/Button";
  import AnimateHeight from "@/UI/AnimateHeight";
  export default {
    components: {
      Button,
      AnimateHeight,
    },
    props: {
      queryResult: {
        type: Object,
        default: () => {},
        required: false,
      },
      type: {
        type: String,
        default: "bank",
      },
    },
    data: () => ({
      dataStatus: DATA_STATUS,
      svgPath: {
        link: mdiLink,
        attachment: mdiAttachment,
        alert: mdiAlertCircleOutline,
        arrowDown: mdiChevronDown,
        refresh: mdiRefresh,
      },
      showExplainer: false,
      verification: { bank: "bank", pdf: "pdf", requestPdf: "requestPdf" },
    }),
    computed: {
      ...mapState({
        eligibleAmount: (state) => state?.userInfo?.creditQualification?.eligibilityCriteriaResult?.eligibleAmount,
      }),
      title() {
        let title;
        if (this.queryResult.dataStatus === this.dataStatus.available && this.eligibleAmount > 0) {
          title = `Congratulations!`;
        } else if (this.queryResult.dataStatus === this.dataStatus.pending) {
          title = `We are working on it`;
        } else {
          title = `Here is what we got`;
        }
        return title;
      },
      alternateVerification() {
        let text;
        if (this.type === this.verification.bank) {
          text = "Upload bank statement";
        } else if (this.type === this.verification.pdf) {
          text = "Connect bank account";
        } else {
          text = "Request statement";
        }
        return text;
      },
    },
    methods: {
      handleClose() {
        this.$emit("close");
        // this.showCreditQualificationStep(false);
      },
      handleConnectBank() {
        this.$emit("connectBank");
      },
    },
  };
</script>
<style scoped>
  /* .icon {
    margin-top: 1rem;
  } */

  .main-icon {
    margin-top: 70px;
  }

  .explainer-grid {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 1rem;
    align-content: center;
  }

  /* .explainer-grid > div {
    display: flex;
    align-items: center;
  } */
  .icon-container {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: #f7f5ff;
    display: grid;
    place-items: center;
  }
</style>
